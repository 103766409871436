import { getUsersByIds, getUsersByPage } from "frontend/api";
import { UserCache, User } from "shared/datamodel/schemas";
import { hasMorePagesAtom, pageAtom, singleUserCacheAtom } from "state-atoms";
import { useGenericCache } from "./use-general-cache";

export function useUsersCache() {
  const { getItems, isLoading, hasMore } = useGenericCache<User, UserCache>({
    cacheAtom: singleUserCacheAtom,
    fetchByIds: getUsersByIds,
    entityName: "users",
    entityKey: "user",
    paginationConfig: {
      hasPagination: true,
      pageAtom,
      hasMorePagesAtom,
      fetchByPage: getUsersByPage,
    },
  });

  return { getUsers: getItems, isLoading, hasMore };
}
